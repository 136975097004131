<template>
  <div class="king">
    <div class="super">
      <div class="holder">
        <Header />
        <div class="container-fluid">
          <div class="blue-bg">
            <div class="payer_texts">
              <h6>
                <span style="font-weight: bold;">The Rent Payer</span> is an
                innovative rent financing solution that seeks to solve problems
                between landlords and tenants in our clime and time. This
                ingenious solution takes out the major conflict between
                landlords and tenants by creating a middle ground where both
                parties can go home smiling.
              </h6>
              <div class="payer_btn">
                <!-- <button class="btn" data-toggle="modal"
                    data-target="#user_type2">Apply now
                </button> -->
                <button class="btn" @click="applyForRent">
                  Apply now
                </button>
              </div>
            </div>
          </div>
          <div class="how_it_works">
            <h4>How it works</h4>
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 com-lg-6">
                  <div class="signup_section">
                    <h6>Sign up</h6>
                    <p>
                      Providing your information, the property details as well
                      as your landlord’s information
                    </p>
                  </div>
                </div>
                <div class="col-md-6 com-lg-6">
                  <div class="rent_payer">
                    <img src="../assets/images/rentpayer.svg" alt="" />
                  </div>
                </div>
                <hr />
                <div class="enrolled row">
                  <div class="col-md-6 com-lg-6">
                    <div class="rent_payer">
                      <img src="../assets/images/rent-enrolled.svg" alt="" />
                    </div>
                  </div>
                  <div class="col-md-6 com-lg-6">
                    <div class="enrolled_section">
                      <h6>Get Enrolled</h6>
                      <p>
                        Get enrolled on our platform once the information
                        provided has been verified.
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="relax row">
                  <div class="col-md-6 com-lg-6">
                    <div class="relax_section">
                      <h6>Relax and rest easy</h6>
                      <p>
                        Now you can relax, rest easy while THE RENT PAYER pays
                        your rent, while you pay now, as you go or later.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 com-lg-6">
                    <div class="rent_payer">
                      <img src="../assets/images/rent-relax.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterMain />
    <!-- Footer-ends -->

    <!-- User Type modal -->
    <!-- Modal -->
    <div
      class="modal fade modal_backdrop"
      id="user_type2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <h6 class="header_text">I AM A:</h6>
          <div class="user__switch">
            <span
              @click.prevent="switchUser('landlord')"
              :class="isActive === 'landlord' ? 'active' : 'inactive'"
              >Landlord</span
            >
            <span
              @click.prevent="switchUser('tenant')"
              :class="isActive === 'tenant' ? 'active' : 'inactive'"
              >Tenants</span
            >
          </div>
          <!-- <div class="modal__btn">
                <button @click="routeLogger()" class="btn">CONTINUE</button>
              </div> -->
        </div>
      </div>
    </div>
    <!-- User Type modal -->
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import { Service } from "../store/service";
import $ from "jquery";
const Api = new Service();

export default {
  name: "RentPayerHome",
  components: {
    Header,
    FooterMain
  },
  data() {
    return {
      loader2: false,
      isActive: "landlord"
    };
  },
  mounted() {},
  methods: {
    applyForRent() {
      this.$gtag.event("rent-financincing-home-click", {
        event_category: "rent financing home documentaion clicks",
        event_label: "Apply Now Button Clicked",
        value: 1
      });
      localStorage.setItem("shelta-loger", "Tenant");
      this.$router.push({
        path: `/dashboard/addpropertytenant`
      });
    },
    switchUser: function(user) {
      this.isActive = user;
      this.routeLogger();
      console.log("Clicked switch");
    },
    routeLogger: function() {
      console.log("clicked on router");
      if (this.isActive === "landlord") {
        // localStorage.setItem("shelta-loger", "Landlord");
        $("#user_type2").modal("hide");
        this.$router.push("/payerlandlord");
      }
      if (this.isActive === "tenant") {
        // localStorage.setItem("shelta-loger", "Tenant");
        $("#user_type2").modal("hide");
        this.$router.push("/payertenant");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
// Gotham: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
// Gotham2: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.king {
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.blue-bg {
  border-radius: 5px;
  background-color: $primary;
  // height: 80vh;
  margin-top: 8rem;
  padding-bottom: 3rem;
}

.payer_texts {
  padding-top: 4rem;
  text-align: left;
  padding-left: 5rem;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-weight: 500;
    font-size: 25px;
    width: 65%;
    line-height: 45px;
    letter-spacing: -0.05em;
    color: $whiteText;
  }
}

.payer_btn {
  button {
    background: $yellow;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05em;
    color: $whiteText;
    text-decoration: none;
  }
}
.how_it_works {
  margin-top: 3rem;
  margin-bottom: 3rem;

  background: #f2f5fe;
  h4 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 29px;
    letter-spacing: -0.05em;
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
}

.signup_section {
  margin-left: 6rem;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: -0.05em;
    color: rgba(0, 0, 0, 0.74);
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 40px;
    text-align: left;
    letter-spacing: -0.05em;
    color: $secondary;
    width: 65%;
  }
}

.rent_payer {
  text-align: right;
  margin-right: 6rem;
}

.enrolled_section {
  margin-left: 6rem;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: -0.05em;
    margin-bottom: 2rem;
    color: rgba(0, 0, 0, 0.74);
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 40px;
    text-align: left;
    letter-spacing: -0.05em;
    color: $secondary;
    width: 65%;
  }
}

// .payer_reasons {
//   padding: 20px 14rem;
// }

.enrolled {
  margin-top: 6rem;
}

.relax {
  margin-top: 6rem;
  padding-bottom: 4rem;
}

img {
  width: 100%;
}

hr {
  width: 90%;
}

.relax_section {
  margin-left: 6rem;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: -0.05em;
    margin-bottom: 2rem;
    color: rgba(0, 0, 0, 0.74);
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 40px;
    text-align: left;
    letter-spacing: -0.05em;
    color: $secondary;
    width: 65%;
  }
}

// User type modal begin
.header_text {
  font-family: Gotham;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: $secondary;
  margin-top: 2rem;
}

.user__switch {
  padding: 44px 20px;
  // margin-top: 1rem;
  margin-bottom: 3rem;
  span:first-child {
    padding: 16px 32px;
    border: 0.5px solid rgba(0, 51, 234, 0.7);
    color: $primary;
    cursor: pointer;
  }
  span:nth-child(2) {
    padding: 16px 32px;
    border: 0.5px solid rgba(0, 51, 234, 0.7);
    border-radius: 0px 5px 5px 0px;
    color: $primary;
    cursor: pointer;
  }
}
.active {
  // padding: 16px 32px;
  background: $primary;
  border-radius: 5px 0px 0px 5px;
  color: #fff !important;
}
.inactive {
  border: 0.5px solid rgba(0, 51, 234, 0.7);
  border-radius: 5px 0px 0px 5px;
}

.checkboxlabel {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #000000;
}
.checkboxlabel ::after {
  cursor: pointer !important;
}

.modal__btn {
  margin: 2rem 0 4rem 0;
  button {
    width: 250px;
    height: 45px;
    left: 595px;
    top: 339px;
    background: $primary;
    border-radius: 5px;
    color: $secondary;
  }
}
.form-check {
  padding-right: 5rem;
}

.modal_backdrop {
  background-color: $primary !important;
}
// User type modal ends

@media only screen and (max-width: 599px) {
  .blue-bg {
    padding-bottom: 1rem;
  }
  .payer_texts {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    h6 {
      width: 100%;
      line-height: 35px;
      font-size: 18px;
    }
  }
  .rent_payer {
    margin-right: 0;
    margin-top: 2rem;
  }
  .how_it_works {
    padding-bottom: 1rem;
    text-align: left;
    margin-bottom: 0;
    h4 {
      font-size: 35px;
      margin-left: 1rem;
      padding-bottom: 2rem;
    }
  }
  .signup_section {
    margin-left: 0;
    h6 {
      font-size: 32px;
      font-weight: 800;
    }
    p {
      width: 100%;
      font-size: 20px;
    }
  }
  .enrolled {
    flex-direction: column-reverse;
    margin-top: 2rem;
  }
  .enrolled_section {
    margin-left: 1rem;

    h6 {
      font-size: 32px;
      font-weight: 800;
    }
    p {
      width: 100%;
      font-size: 20px;
      margin-bottom: 1rem;
    }
  }
  .relax {
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
  .relax_section {
    margin-left: 1rem;
    h6 {
      font-weight: 800;
      font-size: 32px;
    }
    p {
      width: 100%;
      font-size: 20px;
    }
  }
}

@media only screen and (width: 768px) {
  .payer_texts {
    padding: 2rem;
    h6 {
      width: 100%;
    }
  }
  .signup_section {
    margin-left: 2rem;
    p {
      width: 100%;
    }
    h6 {
      font-weight: 800;
    }
  }
  .rent_payer {
    margin-right: 0;
  }
  .enrolled_section {
    margin-left: 0;
    p {
      width: 100%;
    }
  }
  .relax_section {
    margin-left: 2rem;
    p {
      width: 100%;
    }
  }
}

@media screen and (max-width: 320px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
}

@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
}
</style>
